import * as _hasSymbols2 from "has-symbols";

var _hasSymbols = "default" in _hasSymbols2 ? _hasSymbols2.default : _hasSymbols2;

var exports = {};
var toStr = Object.prototype.toString;

var hasSymbols = _hasSymbols();

if (hasSymbols) {
  var symToStr = Symbol.prototype.toString;
  var symStringRegex = /^Symbol\(.*\)$/;

  var isSymbolObject = function isRealSymbolObject(value) {
    if (typeof value.valueOf() !== "symbol") {
      return false;
    }

    return symStringRegex.test(symToStr.call(value));
  };

  exports = function isSymbol(value) {
    if (typeof value === "symbol") {
      return true;
    }

    if (toStr.call(value) !== "[object Symbol]") {
      return false;
    }

    try {
      return isSymbolObject(value);
    } catch (e) {
      return false;
    }
  };
} else {
  exports = function isSymbol(value) {
    // this environment does not support Symbols.
    return false && value;
  };
}

export default exports;